<form [formGroup]="valetForm">
  <div class="row">
    <div class="col-12 text-right" *ngIf="viewAs === ROLE.ADMIN">
      <button type="submit" nbButton size="tiny" (click)="loginAs()">
        Login as
      </button>
    </div>
  </div>
  <pv-common-valet-profile-info [valetForm]="valetForm"
                                [viewAs]="viewAs"
                                [availableMetropolis]="availableMetropolis"
                                [availableTitles]="availableTitles"
                                [valet]="valet"
                                (submitProfilePictureForm)="submitProfilePicture($event)">
  </pv-common-valet-profile-info>
  <common-card
    *ngIf="viewAs === ROLE.ADMIN && !isCandidate">
    <common-card-header>
      <div class="row no-gutters">
        <div class="col-auto mr-2">
          <nb-icon
            icon="clipboard-list"></nb-icon>
        </div>
        <div class="col">
          <h6>
            {{'modules.valet.valet_profile.additional_information' |translate}}
          </h6>
        </div>
      </div>
    </common-card-header>
    <common-card-body>
      <div class="row">
        <div class="col-md-2">
          <div class="form-group">
            <nb-toggle status="warning"
                       formControlName="ready">
              {{'modules.valet.valet_profile.ready' |translate}}
            </nb-toggle>
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <nb-toggle status="warning"
                       formControlName="active">
              {{'modules.valet.valet_profile.active' |translate}}
            </nb-toggle>
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <nb-toggle status="warning"
                       formControlName="sncfStatus">
              {{'modules.valet.valet_profile.status_sncf' |translate}}
            </nb-toggle>
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <nb-toggle status="warning"
                       formControlName="warning">
              {{'modules.valet.valet_profile.warning' |translate}}
            </nb-toggle>
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <nb-toggle status="warning"
                       formControlName="enableLastbill">
              {{'modules.valet.valet_profile.enable_lastbill' |translate}}
            </nb-toggle>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <nb-toggle status="warning"
                       formControlName="blockRemuneration">
              {{'modules.valet.valet_profile.block_remuneration' |translate}}
            </nb-toggle>
          </div>
        </div>
        <div class="col-md-8">
          <div class="form-group">
            <nb-toggle status="warning"
                       formControlName="blockExpenses">
              {{'modules.valet.valet_profile.block_expenses' |translate}}
            </nb-toggle>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label class="label required">
              {{'modules.valet.valet_profile.status' |translate}}
            </label>
            <nb-select formControlName="status" fullWidth>
              <nb-option *ngFor="let status of valetStatus"
                         [value]="status.value">
                {{status.name}}
              </nb-option>
            </nb-select>
            <slnk-shared-input-validator>
              <slnk-shared-input-validator-item
                [validationFormControl]="valetForm.controls['status']"
                [validation]="'required'">
              </slnk-shared-input-validator-item>
            </slnk-shared-input-validator>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label class="label required">
              {{'modules.valet.valet_profile.preferredDistance' |translate}}
            </label>
            <nb-select formControlName="preferredDistance" fullWidth>
              <nb-option *ngFor="let preference of valetPreferences"
                         [value]="preference.value">
                {{preference.name}}
              </nb-option>
            </nb-select>
            <slnk-shared-input-validator>
              <slnk-shared-input-validator-item
                [validationFormControl]="valetForm.controls['preferredDistance']"
                [validation]="'required'">
              </slnk-shared-input-validator-item>
            </slnk-shared-input-validator>
          </div>
        </div>
        <div *ngIf="valet && (!valet.active || !valet.ready)" class="col-md-8">
          <p *ngIf="valet.activeLogs.length !== 0"><strong>{{'modules.valet.valet_profile.disable_date' | translate}}</strong> {{showDisableDate()}}</p>
          <p *ngIf="disableReason"><strong>{{'modules.valet.valet_profile.reason_of_action' | translate}}</strong> {{disableReason}}</p>
          <p *ngIf="disableComment"><strong>{{'modules.valet.valet_profile.comment_of_action' | translate}}</strong> {{disableComment}}</p>
        </div>
      </div>
      <div class="disable_dropdown" *ngIf="historyLogs?.length > 0">
        <div class="disable_dropdown_section"
             [ngClass]="{'show_border_radius': !showDropdown, 'hide_border_radius': showDropdown}"
             (click)="toggleDropdown()">
          <span><strong>{{'modules.valet.valet_profile.history' | translate}}</strong></span>
          <div class="disable_dropdown_arrow">
            <i class="fas fa-chevron-down" *ngIf="!showDropdown"></i>
            <i class="fas fa-chevron-up" *ngIf="showDropdown"></i>
          </div>
        </div>
        <div class="dropdown-content" *ngIf="showDropdown">
          <div class="disable_list_content">
            <div *ngFor="let log of historyLogs; let i = index">
              <ng-container *ngIf="log.action">
                <div class="disable_info_content">
                  <p><strong>{{'modules.valet.valet_profile.date_of_action' | translate}}</strong> {{log.date | momentPipe: 'DD/MM/YYYY à HH:mm'}}</p>
                  <p><strong>{{'modules.valet.valet_profile.action' | translate}}</strong> {{log | profileAction}}</p>
                  <ng-container *ngIf="log | valetIsDisabled">
                    <p><strong>{{'modules.valet.valet_profile.reason_of_action' | translate}}</strong> {{log.disableReason | profileActionReason}}</p>
                    <p><strong>{{'modules.valet.valet_profile.comment_of_action' | translate}}</strong> {{log.disableComment}}</p>
                  </ng-container>
                  <ng-container *ngIf="log | valetHasBlockedInvoicing">
                    <p><strong>{{'modules.valet.valet_profile.reason_of_action' | translate}}</strong> {{log.invoicingBlockReason | profileActionReason}}</p>
                    <p><strong>{{'modules.valet.valet_profile.comment_of_action' | translate}}</strong> {{log.invoicingBlockComment}}</p>
                  </ng-container>
                  <p><strong>{{'modules.valet.valet_profile.admin' | translate}}</strong> {{log.admin?.firstName}} {{log.admin?.lastName}}</p>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </common-card-body>
  </common-card>
  <div class="row">
    <div class="col-auto ml-auto">
      <button *ngIf="!hideSubmitButton"
              nbButton
              type="submit"
              status="primary"
              (click)="submit()"
              [disabled]="valetForm.invalid && !isCandidate">
        {{'global.button.submit'|translate}}
      </button>
    </div>
  </div>
</form>

