import {Injectable} from '@angular/core';
import * as moment from 'moment-timezone';

@Injectable({
  providedIn: 'root'
})
export class UtilsHelper {

  public static IsNilOrEmpty(value: any): boolean {
    if (value === null || value === undefined || value === '') {
      return true
    }

    if (Array.isArray(value)) {
      return value.length === 0;
    }

    return false;
  }

  public static sleep(ms: number) {
    return new Promise<void>(resolve => setTimeout(resolve, ms));
  }

  public static getHumanFormatedDate(date: Date) {
    if (!date) return '';
    return moment(date).format('dddd D MMMM YYYY');
  }
}
