import {Pipe, PipeTransform} from '@angular/core';
import {UtilsHelper} from '../helpers/utils.helper';
import {AbstractControl, FormControl, FormGroup} from '@angular/forms';

@Pipe({
  name: 'isNilOrEmpty'
})
export class IsNilOrEmpty implements PipeTransform {

  transform(value: any): boolean {
    return UtilsHelper.IsNilOrEmpty(value);
  }

}

@Pipe({
  name: 'sortByKey'
})
export class SortByKeyPipe implements PipeTransform {

  transform<T>(value: T[], key: string, order: string = 'asc'): T[] {
    if (!Array.isArray(value) || !key) {
      return value;
    }
    const arrayToSort = [...value]
    arrayToSort.sort((a, b) => a[key] > b[key] ? 1 : -1 );
    return order === 'asc' ? arrayToSort : arrayToSort.reverse();
  }

}

@Pipe({
  name: 'asFormGroup'
})
export class AsFormGroup implements PipeTransform {

  transform(value: any & AbstractControl) {
    return value as FormGroup;
  }

}

@Pipe({
  name: 'asFormControl'
})
export class AsFormControl implements PipeTransform {

  transform(value: any & AbstractControl) {
    return value as FormControl;
  }

}

@Pipe({
  name: 'getHumanFormatedDate'
})
export class GetHumanFormatedDate implements PipeTransform {

  transform(date: Date) {
    return UtilsHelper.getHumanFormatedDate(date);
  }

}

@Pipe({
  name: 'filter'
})
export class Filter implements PipeTransform {

  transform<T>(array: T[], key: string, value: any): T[] {
    if (!key || !value) return array;
    return array.filter((v) => v[key] === value);
  }
}
