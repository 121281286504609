<nb-layout>
  <nb-layout-column style="padding: 0">
    <div class="container-center-horizontal">
      <div class="popvalet-valet-accueil screen">
        <pv-pv-recruitment-common-header class="w-100">
        </pv-pv-recruitment-common-header>

        <div class="overlap-group11-1">
          <div class="fond_home-1"></div>
          <div class="ellipse-29-1"></div>
          <img
            class="stock-photo-cheerful-1"
            src="assets/images/photo-cheerful-chauffeur-smiling-at-camera-home.png"
            alt="stock-photo-cheerful-chauffeur-smiling-at-camera-isolated-on-white-background-1989090218 2"
          />
          <div class="bloc_devenir_valet">
            <div class="contenus_rejoindre_valet">
              <div class="rejoindre-pop-valet-cest isidorasansalt-bold-magic-potion-28px">
                {{'recruitment.home.join_pop_valet' | translate}}
              </div>
              <div class="contenus_rejoindre">
                <div class="planning">
                  <div class="picto_gerez_planning">
                    <img class="icon" src="assets/images/business---calendar-1@2x.png" alt="icon-calendar" />
                  </div>
                  <p class="grez-votre-planning isidorasansalt-bold-magic-potion-16px">{{'recruitment.home.manage_your_planning' | translate}}</p>
                </div>
                <div class="revennus">
                  <div class="overlap-group-39">
                    <div class="group-92">
                      <img
                        class="emoji-winking-facewi"
                        src="assets/images/emoji---winking-face-wink-face-blink-happy-smile-emoji-smile-win@2x.png"
                        alt="Emoji / winking face,wink face,blink,happy,smile,emoji,smile wink,"
                      />
                      <div class="group-91">
                        <p class="gnrez-des-revenus isidorasansalt-bold-magic-potion-16px">{{'recruitment.home.manage_your_income' | translate}}</p>
                      </div>
                    </div>
                    <div class="picto_generez">
                      <div class="picto_revenus">
                        <img
                          class="vector"
                          src="assets/images/vector-31@2x.png"
                          alt="Vector"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="equipe">
                  <div class="picto_gerez_planning picto_pouce">
                    <img class="icon" src="assets/images/user-interface---like-2@2x.png" alt="icon-calendar" />
                  </div>
                  <p class="collaborez-avec-une isidorasansalt-bold-magic-potion-16px">{{'recruitment.home.work_in_team' | translate}}</p>
                </div>
              </div>
              <a style="text-decoration: none; cursor: pointer" (click)="showRecruitmentPage()">
                <div class="cta_devenir_valet-1 cta_devenir_valet-2">
                  <div class="devenir-valet blogtextbold">{{'recruitment.home.become_valet' | translate}}</div>
                </div>
              </a>
            </div>
          </div>
          <div class="accroche_home_pro-1">
            <div class="accroche_home-1">
              <div class="valet-container">
                <h1 class="rejoignez-pop-valet isidorasansalt-semi-bold-magic-potion-40px">{{'recruitment.home.join_pop_valet_and_win_money' | translate}} <span class="underline-yellow">{{'recruitment.home.vehicles' | translate}}</span></h1>
              </div>
            </div>
            <p class="quand-vous-le-voulez">{{'recruitment.home.everywhere_in_france' | translate}}</p>
          </div>
        </div>
        <div class="pop-valet-is-container">
          <h2 class="isidorasansalt-bold-magic-potion-28px"><span class="underline-yellow-popvalet-is">{{'recruitment.home.with_pop_valet' | translate}}</span>{{'recruitment.home.it_is' | translate}}</h2>
          <div class="pop-valet-is-row">
            <div class="pop-valet-is-row-part">
              <div class="pop-valet-is-icon-1">
                <img class="pop-valet-is-icon-img" src="assets/images/vector-30@2x.png" alt="organisation" />
              </div>
              <p class="pop-valet-is-row-part-title isidorasansalt-bold-magic-potion-20px">{{'recruitment.home.missions_organisation' | translate}}</p>
              <p class="pop-valet-is-row-part-description montserrat-normal-magic-potion-14px">{{'recruitment.home.optimize_your_routes' | translate}}</p>
            </div>

            <div class="pop-valet-is-row-part">
              <div class="pop-valet-is-icon-3">
                <img class="pop-valet-is-icon-img" src="assets/images/user-interface---feedback@2x.png" alt="feedback" />
              </div>
              <p class="pop-valet-is-row-part-title isidorasansalt-bold-magic-potion-20px">{{'recruitment.home.relationship_of_trust' | translate}}</p>
              <p class="pop-valet-is-row-part-description montserrat-normal-magic-potion-14px">{{'recruitment.home.assign_missions' | translate}}</p>
            </div>

            <div class="pop-valet-is-row-part">
              <div class="pop-valet-is-icon-5">
                <img class="pop-valet-is-icon-img" src="assets/images/business---euro-coin@2x.png" alt="euro-coin" />
              </div>
              <p class="pop-valet-is-row-part-title isidorasansalt-bold-magic-potion-20px">{{'recruitment.home.weekly_payment' | translate}}</p>
              <p class="pop-valet-is-row-part-description montserrat-normal-magic-potion-14px">{{'recruitment.home.remuneration_every_week' | translate}}</p>
            </div>
          </div>

          <div class="pop-valet-is-row">
            <div class="pop-valet-is-row-part">
              <div class="pop-valet-is-icon-2">
                <img class="pop-valet-is-icon-img" src="assets/images/business---calender@2x.png" alt="calendar" />
              </div>
              <p class="pop-valet-is-row-part-title isidorasansalt-bold-magic-potion-20px">{{'recruitment.home.manage_your_agenda' | translate}}</p>
              <p class="pop-valet-is-row-part-description montserrat-normal-magic-potion-14px">{{'recruitment.home.register_your_availability' | translate}}</p>
            </div>

            <div class="pop-valet-is-row-part">
              <div class="pop-valet-is-icon-4">
                <img class="pop-valet-is-icon-img" src="assets/images/business---credit-card@2x.png" alt="credit-card" />
              </div>
              <p class="pop-valet-is-row-part-title isidorasansalt-bold-magic-potion-20px">{{'recruitment.home.attractive_remuneration' | translate}}</p>
              <p class="pop-valet-is-row-part-description montserrat-normal-magic-potion-14px">{{'recruitment.home.150_per_day' | translate}}</p>
            </div>

            <div class="pop-valet-is-row-part">
              <div class="pop-valet-is-icon-6">
                <img class="pop-valet-is-icon-img" src="assets/images/fleches.png" alt="icon-key" />
              </div>
              <p class="pop-valet-is-row-part-title isidorasansalt-bold-magic-potion-20px">{{'recruitment.home.missions_diversity' | translate}}</p>
              <p class="pop-valet-is-row-part-description montserrat-normal-magic-potion-14px">{{'recruitment.home.unique_missions' | translate}}</p>
            </div>
          </div>
        </div>
        <div class="bloc_video">
          <p class="nos-valets-parlent-de-nous isidorasansalt-bold-magic-potion-28px">{{'recruitment.home.our_valets_talk_about_us' | translate}}</p>
          <div class="overlap-group10-1">
            <div class="video-container">
              <video controls poster="assets/images/valet-video-poster.jpg">
                <source src="https://d17m62rzgdg0on.cloudfront.net/videos/Vidéo+2+(Vf)+-+Phannon-2500k.mp4" type="video/mp4">
              </video>
            </div>
            <p class="name">{{'recruitment.home.video_valet_name' | translate}}</p>
          </div>
        </div>
        <div class="bloc_container_nous_rejoindre">
          <div class="bloc_nous_rejoindre">
            <div class="overlap-group8-1">
              <div class="overlap-group-41">
                <p class="que-vous-faut-il-pour-nous-rejoindre isidorasansalt-bold-magic-potion-28px">
                  {{'recruitment.home.what_do_you_need_to_join_us' | translate}}
                </p>
                <div class="rserver-un-transport-2">
                  <div class="cest-parti"><a style="text-decoration: none" href="https://www.portail-autoentrepreneur.fr/lp/partenaires/popvalet">{{'recruitment.home.let_s_go' | translate}}</a></div>
                </div>
                <a class="cta_devenir_valet-1 cta_devenir_valet-3" style="text-decoration: none; cursor: pointer" (click)="showRecruitmentPage()">
                  <div>
                    <div class="devenir-valet blogtextbold">{{'recruitment.home.become_valet' | translate}}</div>
                  </div>
                </a>
              </div>
              <div class="etape_8points">
                <div class="vignette_8points">
                  <div class="illustration_technologie-1">
                    <img class="permis_fr-1" src="assets/images/permis-fr-1@2x.png" alt="permis_fr 1" />
                  </div>
                </div>
                <img class="line-11" src="assets/images/line-11@2x.png" alt="Line 11" />
                <div class="group-1-1-1">
                  <img
                    class="vector-22"
                    src="assets/images/group-184@2x.png"
                    alt="Vector"
                  />
                  <p class="plus-de-8-points-de-permis montserrat-semi-bold-magic-potion-24px">{{'recruitment.home.more_than_8_points' | translate}}</p>
                </div>
              </div>
              <div class="etape_permis">
                <div class="vignette_3ans_permis"><img class="keys" src="assets/images/keys@2x.png" alt="keys" /></div>
                <img class="line-10" src="assets/images/line-11@2x.png" alt="Line 10" />
                <div class="group-1-1-1 group-178">
                  <img
                    class="vector-22"
                    src="assets/images/group-184@2x.png"
                    alt="Vector"
                  />
                  <p class="plus-de-8-points-de-permis montserrat-semi-bold-magic-potion-24px">{{'recruitment.home.3_years_driving_licence' | translate}}</p>
                </div>
              </div>
              <div class="tape_autoentrepreuneur">
                <div class="overlap-group1-14">
                  <div class="illustration_technologie-2"></div>
                  <div class="status-updaterafiki">
                    <div class="status-update">
                      <img class="vector-1" src="assets/images/background-simple@2x.png" alt="Vector" />
                      <div class="vector-container">
                        <img class="vector-2" src="assets/images/vector-9@2x.png" alt="Vector" /><img
                        class="vector-3"
                        src="assets/images/vector-10@2x.png"
                        alt="Vector"
                      />
                      </div>
                      <div class="overlap-group-42">
                        <img class="vector-4" src="assets/images/vector-11@2x.png" alt="Vector" /><img
                        class="vector-5"
                        src="assets/images/vector-12@2x.png"
                        alt="Vector"
                      /><img class="vector-6" src="assets/images/vector-13@2x.png" alt="Vector" /><img
                        class="vector-7"
                        src="assets/images/vector-14@2x.png"
                        alt="Vector"
                      /><img class="group-9" src="assets/images/group-2@2x.png" alt="Group" /><img
                        class="vector-8"
                        src="assets/images/vector-15@2x.png"
                        alt="Vector"
                      /><img class="vector-9" src="assets/images/vector-16@2x.png" alt="Vector" /><img
                        class="vector-10"
                        src="assets/images/vector-17@2x.png"
                        alt="Vector"
                      /><img class="vector-11" src="assets/images/vector-18@2x.png" alt="Vector" /><img
                        class="vector-12"
                        src="assets/images/vector-19@2x.png"
                        alt="Vector"
                      /><img class="vector-13" src="assets/images/vector-20@2x.png" alt="Vector" /><img
                        class="vector-14"
                        src="assets/images/vector-21@2x.png"
                        alt="Vector"
                      /><img class="vector-15" src="assets/images/vector-22@2x.png" alt="Vector" /><img
                        class="vector-16"
                        src="assets/images/vector-23@2x.png"
                        alt="Vector"
                      /><img class="vector-17" src="assets/images/vector-24@2x.png" alt="Vector" /><img
                        class="vector-18"
                        src="assets/images/vector-25@2x.png"
                        alt="Vector"
                      /><img class="vector-19" src="assets/images/vector-26@2x.png" alt="Vector" /><img
                        class="vector-20"
                        src="assets/images/vector-28@2x.png"
                        alt="Vector"
                      /><img class="group-226" src="assets/images/group-226@2x.png" alt="Group 226" />
                        <div class="auto-entrepreneur">{{'recruitment.home.auto' | translate}} <br />{{'recruitment.home.contractor' | translate}}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <img class="line-8" src="assets/images/line-11@2x.png" alt="Line 8" />
                <div class="overlap-group2-2">
                  <div class="group-177">
                    <div class="group-176">
                      <div class="group-174">
                        <img
                          class="vector-24"
                          src="assets/images/group-184@2x.png"
                          alt="Vector"
                        />
                        <div class="un-statut-dauto-entrepreneur-actif montserrat-semi-bold-magic-potion-24px">
                          {{'recruitment.home.register_and_check_eligibility' | translate}}
                        </div>
                      </div>
                      <p class="vous-navez-pas-de-s montserrat-medium-white-20px">
                    <span class="montserrat-medium-magic-potion-20px">{{'recruitment.home.no_auto_entrepeneur_status' | translate}}</span
                    ><br><span class="span-40">{{'recruitment.home.create_auto_entrepreneur_status' | translate}}</span>
                      </p>
                    </div>
                  </div>
                  <img class="vector-1-1" src="assets/images/vector-1-1.png" alt="Vector 1" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bloc_etapes_nous_rejoindre">
          <p class="les-tapes-pour-nous-rejoindre isidorasansalt-bold-magic-potion-28px">
            {{'recruitment.home.steps_to_join_us' | translate}}
          </p>
          <div class="overlap-group6-1 first-step-joinus">
            <div class="etape_sinscrire">
              <div class="vignette_sinscrire">
                <img class="etapes_inscription" src="assets/images/etapes-inscription@2x.png" alt="etapes_inscription" />
              </div>
              <img class="line-13" src="assets/images/line-11@2x.png" alt="Line 13" />
              <div class="group-176-1">
                <div class="group-174">
                  <img
                    class="vector-24"
                    src="assets/images/group-184@2x.png"
                    alt="Vector"
                  />
                  <div class="un-statut-dauto-entrepreneur-actif montserrat-semi-bold-magic-potion-24px">
                    {{'recruitment.home.register_and_check_eligibility' | translate}}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="overlap-group6-1">
            <div class="etape_sinscrire">
              <div class="vignette_sinscrire">
                <img class="etapes_inscription" src="assets/images/etapes-reunion@2x.png" alt="etapes_inscription" />
              </div>
              <img class="line-13" src="assets/images/line-11@2x.png" alt="Line 13" />
              <div class="group-176-1">
                <div class="group-174">
                  <img
                    class="vector-24"
                    src="assets/images/group-184@2x.png"
                    alt="Vector"
                  />
                  <div class="un-statut-dauto-entrepreneur-actif montserrat-semi-bold-magic-potion-24px">
                    {{'recruitment.home.attend_a_meeting' | translate}}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="overlap-group6-1">
            <div class="etape_sinscrire">
              <div class="vignette_sinscrire">
                <img class="etapes_contrat" src="assets/images/etapes-contrat@2x.png" alt="etapes_inscription" />
              </div>
              <img class="line-13" src="assets/images/line-11@2x.png" alt="Line 13" />
              <div class="group-176-1">
                <div class="group-174">
                  <img
                    class="vector-24"
                    src="assets/images/group-184@2x.png"
                    alt="Vector"
                  />
                  <div class="un-statut-dauto-entrepreneur-actif montserrat-semi-bold-magic-potion-24px">
                    {{'recruitment.home.sign_a_contract' | translate}}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="etape_formation">
            <div class="group-container-5">
              <div class="group-186">
                <div class="group-173">
                  <div class="iso-certificationrafiki">
                    <img class="background-simple" src="assets/images/background-simple@2x.png" alt="background-simple" />
                    <div class="overlap-group-43">
                      <img class="background-complete" src="assets/images/background-complete@2x.png" alt="background-complete" /><img
                      class="shadow"
                      src="assets/images/shadow@2x.png"
                      alt="Shadow"
                    /><img class="certificate" src="assets/images/certificate@2x.png" alt="Certificate" /><img
                      class="sparkles"
                      src="assets/images/sparkles@2x.png"
                      alt="Sparkles"
                    /><img class="character-1" src="assets/images/character-1@2x.png" alt="character-1" />
                    </div>
                  </div>
                </div>
              </div>
              <img class="group-204" src="assets/images/group-204@2x.png" alt="Group 204" />
            </div>
            <img class="line-1" src="assets/images/line-11@2x.png" alt="Line 16" />
            <div class="group-1-1-1 group-1-1">
              <img
                class="vector-22"
                src="assets/images/group-184@2x.png"
                alt="Vector"
              />
              <p class="plus-de-8-points-de-permis montserrat-semi-bold-magic-potion-24px">{{'recruitment.home.follow_a_training' | translate}}</p>
            </div>
          </div>
          <div class="overlap-group7-1">
            <a style="text-decoration: none; cursor: pointer" (click)="showRecruitmentPage()">
              <div class="cta_devenir_valet-1 cta_devenir_valet-4">
                <div class="devenir-valet blogtextbold">{{'recruitment.home.become_valet' | translate}}</div>
              </div>
            </a>
            <div class="etape_gagner_argent">
              <div class="overlap-group-44">
                <div class="illustration_technologie-3"></div>
                <img class="etapes_mission" src="assets/images/etapes-mission@2x.png" alt="etapes_mission" />
              </div>
              <img class="line-17" src="assets/images/line-11@2x.png" alt="Line 17" />
              <div class="group-189">
                <img class="vector-21" src="assets/images/group-184@2x.png" alt="Vector" />
                <p class="commencer-ses-missio montserrat-semi-bold-magic-potion-24px">{{'recruitment.home.start_missions_and_win_money' | translate}}</p>
              </div>
            </div>
          </div>
        </div>
<!--        &lt;!&ndash;      <bloc-devenir-valet :champsInscriptionProps="blocDevenirValetProps.champsInscriptionProps" />&ndash;&gt;-->
        <div class="overlap-group-container">
          <div class="overlap-group3-1">
            <div class="fond_acces_pro"></div>
            <img class="demi_cercle_yellow" src="assets/images/demi-cercle-yellow-1@2x.png" alt="demi_cercle_yellow" />
            <div class="contenu_acces_pro">
              <p class="vous-souhaitez-dcou isidorasansalt-bold-magic-potion-28px">
              <span class="isidorasansalt-bold-magic-potion-28px">{{'recruitment.home.discover_pop_valet' | translate}}</span
              ><br><span class="span-41 blogintro">{{'recruitment.home.discover_our_page' | translate}}</span>
              </p>
              <a style="text-decoration: none; cursor: pointer" href="https://www.popvalet.com/professionnels">
                <div class="cta_acces_pro_negatif">
                  <div class="accs-pro blogtextbold">{{'recruitment.home.pro_access' | translate}}</div>
                </div>
              </a>
            </div>
            <img
              class="stock-photo-close-up-1"
              src="assets/images/photo-close-up-of-the-car-owner-s-hand-holding-the-deliver-1@2x.png"
              alt="stock-photo-close-up-of-the-car-owner-s-hand-holding-the-delivery-key-to-buyers-concept-of-selling-cars-and-2183482591 2"
            />
          </div>
        </div>
        <div class="maif-container">
          <div class="maif-component">
            <p class="maif-text isidorasansalt-bold-magic-potion-28px">
              {{'recruitment.home.pop_valet_assurance' | translate}}
            </p>
            <div class="maif-logo">
              <p class="maif-logo-text montserrat-medium-magic-potion-20px">
                {{'recruitment.home.maif' | translate}}
              </p>
              <a href="https://www.popvalet.com/popvalet-general-assurance"><img class="maif-logo-img" src="assets/images/altima_logo.png" alt="Logo Altima"/></a>
            </div>
          </div>
        </div>
        <pv-pv-recruitment-common-footer class="w-100">
        </pv-pv-recruitment-common-footer>
      </div>
    </div>
  </nb-layout-column>
</nb-layout>
