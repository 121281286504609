import * as moment from 'moment/moment';

export class DurationHelper {
  static humanDuration(duration: number | null) {
    if (duration === null) {
      return '';
    }

    // Round to next 5 minutes (300 sec)
    duration += 300 - duration % 300;

    const d = moment.duration(duration, 'seconds');

    if (d.hours() > 0) {
      return d.hours() + ' h ' + d.minutes() + ' min';
    } else {
      return d.minutes() + ' min';
    }
  }
}
