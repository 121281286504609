import {NgModule} from '@angular/core';
import {AsFormGroup, IsNilOrEmpty, SortByKeyPipe, GetHumanFormatedDate, AsFormControl, Filter} from './utils.pipe';
import {GetCost, GetPrice} from './batch.pipe';
import {MissionTypeTranslatePipe} from './mission-type-translate.pipe';
import {GetFirstMissionType, GetSecondMissionType} from './order.pipe';
import {
  GetCustomValueLabelPipe,
  GetDateLabelHtmlPipe,
  GetUnitLabel,
  GetValuesForUnit,
  IsRangeDate
} from './date-filter.pipe';
import {HasValidator} from './has-validator.pipe';
import {HumanDuration} from './duration.pipe';

@NgModule({
  declarations: [
    IsNilOrEmpty,
    GetCost,
    GetPrice,
    SortByKeyPipe,
    AsFormGroup,
    AsFormControl,
    MissionTypeTranslatePipe,
    GetFirstMissionType,
    GetSecondMissionType,
    GetValuesForUnit,
    GetUnitLabel,
    GetDateLabelHtmlPipe,
    GetCustomValueLabelPipe,
    IsRangeDate,
    HasValidator,
    GetHumanFormatedDate,
    Filter,
    HumanDuration
  ],
  exports: [
    IsNilOrEmpty,
    GetCost,
    GetPrice,
    SortByKeyPipe,
    AsFormGroup,
    AsFormControl,
    MissionTypeTranslatePipe,
    GetFirstMissionType,
    GetSecondMissionType,
    GetValuesForUnit,
    GetUnitLabel,
    GetDateLabelHtmlPipe,
    GetCustomValueLabelPipe,
    IsRangeDate,
    HasValidator,
    GetHumanFormatedDate,
    Filter,
    HumanDuration
  ],
})
export class PipesModule {
}
