import {Pipe, PipeTransform} from '@angular/core';
import {DurationHelper} from '../helpers/duration.helper';

@Pipe({
  name: 'humanDuration'
})
export class HumanDuration implements PipeTransform {

  transform(duration: number | null): string {
    return DurationHelper.humanDuration(duration);
  }

}
